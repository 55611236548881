<template>
  <div>
    <NavBar />
    <v-main :class="getClass">
      <v-container  fluid>
        <router-view></router-view>
      </v-container>
      <!--<Footer />!-->
    </v-main>
    <v-divider></v-divider>
  </div>
</template>
<script>
import { NavBar, Footer } from "../components/_partials";
import { mapState } from "vuex";

export default {
  name: "AdminShell",
  components: {
    NavBar,
    Footer,
  },
  computed: {
    ...mapState({
      ui: (state) => state.ui,
    }),
    getClass() {
      return this.ui.navBarOpen ? `v-main-contract` : `v-main-expanded`;
    },
  },
};
</script>
