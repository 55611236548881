<template lang="">
  <div>
    <!-- TOP NAVBAR -->
    <v-app-bar id="fvl-nav-bar" app :class="getClass" color="primary" height="46px" dark>
      <router-link  height="46px" class="fvl-a-logo-site ma-0 pa-0" :to="links.home">
      <img src="../../assets/gbs-logo.png" class="logo-gbs">
      </router-link>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="icon-nav-bar" @click="changeNavBarState"></v-app-bar-nav-icon>
    </v-app-bar>
    <!-- END TOP NAVBAR -->

    <!-- LEFT MENU -->
    <v-navigation-drawer
      class="nav-personalized"
      hide-overlay
      fixed
      left
      :value="ui.navBarOpen"
    >
   
    <v-list class="container-avatar">
      <div class="header-container-avatar" >
        <router-link class="link-subitem"  :to="links.profile.detail">
          <v-avatar id="fvl-avatar" class="elevation-1" color="img-avatar" size="35">
            <span>
              {{ user.person.name[0]}}
            </span>
          </v-avatar>
        </router-link>

         <v-btn
            class="fvl-btn transparent-secundary mr-2"
            elevation="0"
            icon
            @click="changeNavBarState"
            >
            <v-icon>menu_open</v-icon>
          </v-btn>
      </div>
      <v-list-item class="content-avatar info-avatar" v-if="user">      
        <v-list-item-title class="text-h6" :title="user.person.name">
          {{ user.person.name }}            
        </v-list-item-title>
      </v-list-item>
      <v-list-item link v-if="user" class="info-user-avatar">
        <v-list-item-content>
          <v-list-item-subtitle class="email-user-avatar">{{ user.email }}</v-list-item-subtitle>
          <v-list-item-subtitle class="type-person-user-avatar">
              {{user.person.types_person.description}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    
    <v-list nav dense class="container-item">
        <div v-for="(item, index) in menuItems" class="mb-1 mr-3">
          <v-list-item class=" border-bottom-item" v-if="checkMenu(item)">
            <v-btn 
              href="https://visor.gbsarchitectural.com/" 
              target="_blank" 
              block
              elevation="0" 
              class="fvl-btn fvl-btn-block transparent-secundary" 
              v-if="index === 3">
              <v-icon small left>{{ item.icon }}</v-icon>{{ item.name }}
            </v-btn>
            <v-btn
              :to="item.path"
              :key="index"
              block
              elevation="0"
              class="fvl-btn fvl-btn-block transparent-secundary"
              v-if="index !== 3">
             <v-icon small left>{{ item.icon }}</v-icon>{{ item.name }}
            </v-btn>

          </v-list-item>
        </div>
      </v-list>
      <v-list nav dense class=" mb-1 mr-3">
        <v-list-item class="list-item">

          <v-btn
            block    
            elevation="0"
            class="fvl-btn fvl-btn-block transparent-secundary"
            @click="logOut">
             <v-icon small left>logout</v-icon>Cerrar Sesión
            </v-btn>


        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import menu from '../../helpers/menu';
import links from '../../helpers/links'
import permissions from '../../helpers/permissions'
export default {
  name: "NavBar",
  data() {
    return {
      drawer: true,
      menuItems: menu,
      links:links
    };
  },
  computed: {
    ...mapState({
      user:(state) =>state.user,
      ui: (state) => state.ui,
    }),
    getClass(){
      return this.ui.navBarOpen ? `top-app-bar` : `top-app-bar-closed`
    }
  },
  methods: {
    ...mapActions(["logOut"]),
    checkMenu(item){
      if (item.permission===permissions.no_need || this.user.person.types_person.id===3 ) {
        return true
      }
      const isOk= this.user.permissions.indexOf(item.permission) >=0 ? true : false;
      return isOk;
    },
    changeNavBarState(){
      this.$store.dispatch("setNavBarOpenAction");
    }
  },
};
</script>

