<template lang="">
  <v-footer color="gray" class="footer-login" id="foot-login">
    <div class="border-line"></div>
    <v-col class="text-center" cols="12">
      Copyright © {{ new Date().getFullYear() }} GBS Architectural | Powered by
      <span>Filevel Software ®</span>
    </v-col>
  </v-footer>
</template>
<script>
export default {};
</script>
<style lang=""></style>
