<template>
  <v-footer class="text-center"> 
    <h5 class="h5-footer"><span class="footer-text">Copyright © 2021 GBS Architectural Srl | Powered by</span> Filevel Software ®</h5>      
  </v-footer>
</template>

<script>
export default {};
</script>

<style>
</style>